@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

/* .App {
  text-align: center;

} */
html{
  scroll-behavior: smooth;
}
body{
  overflow-x: hidden;
}

.custom-cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  background-color: #ff5733; /* Customize the color */
  border-radius: 50%;
  pointer-events: none; /* Prevent cursor from interfering with interactions */
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease, background-color 0.3s ease;
  mix-blend-mode: difference; /* Customize blend mode */
}

.chipc{
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 8vh;
  /* right: 12vw; */
  background-color: #D9CAB3;
  padding-top: 5px;
  
}

.grid-container {
  display: grid;
  grid-template-columns: auto;
  background-color: #6D9886;
  padding: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  margin: 1px;
  text-align: center;
  cursor: pointer;
}
.abthr{
  width: 35vw;
}

.mymap{
  border: 0;
  width: 42vw;
  height: 55vh;

}

@media (max-width : 1500px) {
  .mymap{
    border: 0;
    width: 500px;
    height: 450px;
  
  }
}


@media (max-width : 1023px) {
  .chipc{
    display: none;
  }
  .mymap{
    border: 0;
    width: 80vw;
    height: 60vh;
  
  }
}

@media (max-width : 640px) {
  .abthr{
    width: 55vw;
  }
}
