.formdiv{
    margin-top: -15vw;
    margin-left: 57vw;
    position: relative;
    z-index: 10;
}
.subdiv{
    box-shadow: 0px 0px 19px 4px #dcdbdb;
    border-radius: 20px;
    border: 2px solid black;
}

@media screen and (max-width : 1280px) {
    .formdiv{
        margin-top: -12vw;
    }
}
@media screen and (max-width : 1080px) {
    .formdiv{
        display: none;
    }
}