.aboutlist{
    display: flex;
    justify-content: center;
}
.list{
    margin: 20px;
    border-bottom: 3px solid #6D9886;
    padding: 5px;
    /* background-color: #6D9886; */
    /* color: #F6F6F6; */
    /* border-radius: 20px; */
    font-weight: 700;
}
.abtimg{
    border-radius: 20px;
    opacity: 0.9;
}
